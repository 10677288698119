import React, { useState, useEffect } from "react";
import { TableRow } from "./TableRow";
import { DropdownBtn } from "./DropdownBtn";
import useOpenController from "../../Hooks/useOpenController";
import { getCdrGroup } from "../../services/billing";

export const TableSection = ({ personDetails, index }) => {
    const { isOpen, toggle, setOpenState } = useOpenController(false);
    const [infoGroup, setInfoGroup] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const startDate = new Date(personDetails.start);
    const date = startDate.toLocaleDateString();
    const options = { hour: "numeric", minute: "numeric", second: "numeric" };
    const phoneNumber = personDetails?.src;
    const formattedPhoneNumber = phoneNumber
        ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : null;
    const destNumber = personDetails?.dst;
    const formattedDestNumber = destNumber
        ? destNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : null;
    const time = startDate.toLocaleTimeString(undefined, options);
    function formatSecondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true); 
                if (!isOpen) return;
                const response = await getCdrGroup(250, personDetails.group_id);
                setInfoGroup(response?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false); 
            }
        };

        fetchData();
    }, [isOpen]);

    useEffect(() => {
        setOpenState(false)
    }, [personDetails]);

    return (
        <>
            <tr>
                <td className="button-td">
                    <DropdownBtn isOpen={isOpen} toggle={toggle} />
                </td>
                <td>
                    {formattedPhoneNumber}
                    <span>{personDetails.src_cnam}</span>
                </td>
                <td>{formattedDestNumber}</td>
                <td>{personDetails.dst_type}</td>
                <td>{date}</td>
                <td>{time}</td>
                <td>{formatSecondsToTime(personDetails.billsec)}</td>
            </tr>
            {isLoading ? (
                <tr>
                    <td colSpan="7">Loading...</td>
                </tr>
            ) : (
                isOpen &&
                infoGroup.map((info) => <TableRow personDetails={info} />)
            )}
        </>
    );
};