import React, { useState, useEffect } from "react";
import infoIcon from "../../assets/images/svg/info-icon.svg";
const UseCaseForm = ({ setStatus, isVisible }) => {
    const [isUseCaseSectionVisible, setUseCaseSectionVisible] = useState(false);
    const [formData, setFormData] = useState({
        campaignDescription: "",
        messageFlow: "",
        email: "",
        sampleMessage1: "",
        sampleMessage2: "",
        sampleMessage3: "",
        affiliateMarketingConfirmed: false
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [localStatus, setLocalStatus] = useState("Incomplete");

    const statusStyles = {
        Incomplete: { color: "red" },
        Pending: { color: "orange" },
        Approved: { color: "green" },
        Default: { color: "red" }
    };

    const getStatusStyle = (status) => {
        return statusStyles[status] || statusStyles.Default;
    };

    useEffect(() => {
        const savedFormData = localStorage.getItem("useCaseFormData");
        const submissionStatus = localStorage.getItem("useCaseFormSubmitted");

        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
        }
        if (submissionStatus === "true") {
            setIsSubmitted(true);
            setLocalStatus("Approved");
            setStatus("Approved");
        }
    }, [setStatus]);

    useEffect(() => {
        const { campaignDescription, messageFlow, email, sampleMessage1 } = formData;

        const allRequiredFieldsFilled =
            campaignDescription.trim() &&
            messageFlow.trim() &&
            email.trim() &&
            sampleMessage1.trim();

        setIsFormValid(allRequiredFieldsFilled);

        if (isVisible === "Approved") {
            setUseCaseSectionVisible(!allRequiredFieldsFilled);
        }
    }, [formData, isVisible]);
    
    useEffect(() => {
        if (localStatus === "Pending") {
            const timer = setTimeout(() => {
                setLocalStatus("Approved");
                setStatus("Approved");
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [localStatus, setStatus]);


    const toggleUseCaseSection = () => {
        setUseCaseSectionVisible(!isUseCaseSectionVisible);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (isSubmitted) return; // Prevent changes if the form is submitted
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            localStorage.setItem("useCaseFormData", JSON.stringify(formData));
            localStorage.setItem("useCaseFormSubmitted", "true");

            setIsSubmitted(true);
            setLocalStatus("Pending");
            setStatus("Pending");
            alert("Form submitted and saved successfully!");
        } else {
            alert("Please fill all required fields.");
        }
    };

    return (
        <div className="container p-4 pb-0 bg-white rounded-4 my-4">
            <div className="border-bottom pb-4">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <h2 className="fs-4 fw-normal font-weight-bold mb-0 pb-0">
                        Use Case <span style={getStatusStyle(localStatus)} className="fw-normal fs-6 d-inline-block">{localStatus}</span>
                    </h2>
                    <button
                        onClick={toggleUseCaseSection}
                        style={{
                            backgroundColor: isUseCaseSectionVisible ? "#0A0A0A14" : "#fff",
                            width: "64px",
                            height: "64px",
                            borderRadius: "12px",
                            padding: "8px",
                            border: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {isUseCaseSectionVisible ? (
                            <div
                                style={{
                                    width: "21.33px",
                                    height: "2.67px",
                                    backgroundColor: "#666666",
                                }}
                            ></div>
                        ) : (
                            <div
                                style={{
                                    position: "relative",
                                    width: "21.33px",
                                    height: "2.67px",
                                    backgroundColor: "#666666",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        width: "21.33px",
                                        height: "2.67px",
                                        backgroundColor: "#666666",
                                        transform: "rotate(90deg)",
                                    }}
                                ></div>
                            </div>
                        )}
                    </button>
                </div>
                {(localStatus === "Pending") && (
                    <div
                        className="alert d-flex alert-warning border border-warning-subtle border-2 px-2 align-items-start"
                        style={{width: "555px"}} role="alert">
                        <img
                            width="20"
                            height="20"
                            src={infoIcon}
                            className="mx-2 my-1"
                            alt="info Icon"
                            decoding="async"
                        />
                        <div>
                            <span>We will let you know once you are approved. This usually takes 5 minutes.</span>
                        </div>
                    </div>
                )}
            </div>

            {isUseCaseSectionVisible && (
                <form className="row g-4 mt-4" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <div className="mb-5">
                            <div className="d-flex align-items-center justify-content-between">
                                <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                    Campaign description*
                                </label>
                                <span className="font-size-sm text-secondary">{formData.campaignDescription.length}/4096</span>
                            </div>
                            <textarea
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                name="campaignDescription"
                                rows="5"
                                value={formData.campaignDescription}
                                onChange={handleInputChange}
                                maxLength={4096}
                                required
                                readOnly={isSubmitted}
                            ></textarea>
                            <span className="d-flex text-secondary mt-1" style={{ fontSize: '12px' }}>
                                Provide details on the purpose behind your text messaging use case for your organization
                            </span>
                        </div>
                        <div className="mb-5">
                            <div className="d-flex align-items-center justify-content-between">
                                <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                    Call-to-action / Message flow*
                                </label>
                                <span className="font-size-sm text-secondary">{formData.messageFlow.length}/4096</span>
                            </div>
                            <textarea
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                name="messageFlow"
                                rows="5"
                                value={formData.messageFlow}
                                onChange={handleInputChange}
                                maxLength={4096}
                                required
                                readOnly={isSubmitted}
                            ></textarea>
                            <span className="d-flex text-secondary mt-1" style={{ fontSize: '12px' }}>
                                Describe how recipients have given their consent to receive text messages from your organization
                            </span>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Email*
                            </label>
                            <input
                                type="email"
                                className={`form-control p-3 ${isSubmitted ? 'bg-light ' : ''}`}
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                readOnly={isSubmitted}
                            />
                            <span className="d-flex text-secondary mt-1" style={{ fontSize: '12px' }}>
                                This e-mail address will be provided to recipients when they reply with HELP.
                            </span>
                        </div>
                    </div>

                    <div className="col-md-6">
                        {["sampleMessage1", "sampleMessage2", "sampleMessage3"].map((name, index) => (
                            <div key={name} className="mb-5">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                        Sample message {index + 1} {index === 0 ? "*" : "(optional)"}
                                    </label>
                                    <span className="font-size-sm text-secondary">{formData[name].length}/1024</span>
                                </div>
                                <textarea
                                    className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                    name={name}
                                    rows="5"
                                    value={formData[name]}
                                    onChange={handleInputChange}
                                    maxLength={1024}
                                    required={index === 0}
                                    readOnly={isSubmitted}
                                ></textarea>
                                <span className="d-flex text-secondary mt-1" style={{ fontSize: '12px' }}>
                                    Enter a sample message which you intend on sending to recipients
                                </span>
                            </div>
                        ))}
                        <div className="d-flex align-items-center gap-2">
                            <input
                                type="checkbox"
                                className="form-check-input m-0"
                                name="affiliateMarketingConfirmed"
                                checked={formData.affiliateMarketingConfirmed}
                                onChange={handleInputChange}
                                disabled={isSubmitted}
                            />
                            <label className="form-check-label font-weight-bold">
                                I confirm that this campaign will not be used for Affiliate Marketing
                            </label>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end my-4">
                        <button
                            type="submit"
                            className={`btn ${isSubmitted ? "btn-secondary" : "btn-primary"}`}
                            disabled={isSubmitted || !isFormValid}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default UseCaseForm;
