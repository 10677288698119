import React from "react";
import ArrowRight from "../../assets/images/svg/ArrowRight";

export const DropdownBtn = ({ isOpen, toggle }) => {
    return (
        <button onClick={toggle}>
            <span
                className="arrow-right-btn"
                style={{
                    transform: `rotate(${isOpen ? 90 : 0}deg)`,
                    transition: "all 0.25s",
                }}
            >
                <ArrowRight />
            </span>
        </button>
    );
};
