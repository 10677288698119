import React from "react";
import answer from "../../assets/images/answer.svg";
import noanswer from "../../assets/images/noanswer.svg";
export const TableRow = ({ personDetails }) => {
    const startDate = new Date(personDetails.start);
    const date = startDate.toLocaleDateString();
    const options = { hour: "numeric", minute: "numeric", second: "numeric" };
    const time = startDate.toLocaleTimeString(undefined, options);
    const phoneNumber = personDetails.dst
    const formattedPhoneNumber = phoneNumber
        ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : null;
    function formatSecondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return (
        <tr className="info">
            <td></td>
            <td className="disposition">
                {personDetails.disposition === "ANSWER" ? (
                    <span><img src={answer} alt="empty calls" /></span>
                ) : (
                  <span><img src={noanswer} alt="empty calls" /></span>
                )}
            </td>
            <td>
                {formattedPhoneNumber} <span>{personDetails.dst_cnam}</span>
            </td>
            <td>{personDetails.dst_type}</td>
            <td>{date}</td>
            <td>{time}</td>
            <td>{formatSecondsToTime(personDetails.billsec)}</td>
        </tr>
    );
};
