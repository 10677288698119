import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
/* @todo */
const SharedLayout = () => {
    return (
        <>
            404
        </>
    );
};
export default SharedLayout;
