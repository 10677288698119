import React, { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { Table } from "../components/table/Table";
import usericon from "../assets/images/Userpic.svg";
import { logout } from "../services/billing";
import { LocalStorage } from "../app/helpers";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";

export default function CallRecords() {
    const user = LocalStorage.getUser();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logout().then((response) => {
                LocalStorage.setRefreshToken();
                LocalStorage.setAccessToken();
                LocalStorage.setUser();
                navigate("/");
            });
        } catch (ex) {
            LocalStorage.setRefreshToken();
            LocalStorage.setAccessToken();
            LocalStorage.setUser();
            navigate("/");
        }
    };
    return (
        <div className="personal-account">
            <Sidebar />
            <div className="right-content">
                <div className="call-records">
                    <div className="top-panel">
                        <h2>Call records</h2>
                        <div className="col-xl-3 d-none d-xl-block ctaDesktop">
                            {(user?.customer && (
                                <div
                                    id="ctaDsktopmenu"
                                    className="dropdown userLog loginLogout"
                                >
                                    <div className="menuTopNav">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                <img
                                                    width="34"
                                                    height="34"
                                                    src={usericon}
                                                    className="userPic"
                                                    alt="Qara"
                                                    decoding="async"
                                                />
                                                <span className="userName">
                                                    {user.customer.firstName}{" "}
                                                    {user.customer.lastName}
                                                </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        navigate("/billing")
                                                    }
                                                    as="button"
                                                >
                                                    Billing
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={handleLogout}
                                                >
                                                    Log out
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            )) || (
                                <div
                                    id="menudesktop"
                                    className="menuTopNav userLogAct"
                                >
                                    <ul className="navbar-nav d-flex ms-auto navbar-nav-right justify-content-end align-items-center">
                                        <li className="loginBtn menu-item">
                                            <Link
                                                to="/login"
                                                className="mr-xl-3"
                                            >
                                                Login
                                            </Link>
                                        </li>
                                        <li className="freetrial_butn menu-item">
                                            <Link
                                                to="https://qara.app/try-qara-for-free/"
                                                className="btn btn-primary"
                                            >
                                                Try for free
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <form action="#" className="date-filter">
                    <label htmlFor="start">
                        <span>Start date</span>
                        <div className="date-input">
                            <input
                                type="date"
                                id="start"
                                onChange={(e) => setStartDate(new Date(e.target.value))}
                            />
                        </div>
                    </label>
                    <label htmlFor="end">
                        <span>End date</span>
                        <div className="date-input">
                            <input
                                type="date"
                                id="end"
                                onChange={(e) => setEndDate(new Date(e.target.value))}
                            />
                        </div>
                    </label>
                </form>
                <Table
                    startDate={startDate || new Date(0)}
                    endDate={endDate || new Date()}
                />
            </div>
        </div>
    );
}
