import React, {useContext, useEffect, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../../services/billing";
import darkLogo from "../../assets/images/darkLogo.svg";
import usericon from "../../assets/images/Userpic.svg";
import ProjectContext from "../../context/ProjectContext";
import {globalConstants} from "../../services/globalVariables";
import PostSubscriptionErrorModal from "../PostSubscriptionErrorModal/PostSubscriptionErrorModal";
import SocialLinks from "../global/SocialLinks";
import MainMenu from "../global/MainMenu";
import {LocalStorage} from "../../app/helpers";

const SITE_URL = globalConstants.SITE_URL;

const Header = () => {
    const navigate = useNavigate();
    const [navOpen, setNavOpen] = useState(false);
    const [headerFixed, setHeaderFixed] = useState(false);
    const {showSubscriptionError, handleShowSubscriptionError} =
        useContext(ProjectContext);

    const user = LocalStorage.getUser();
    const handleLogout = async () => {
        try {
            await logout().then((response) => {
                LocalStorage.setRefreshToken()
                LocalStorage.setAccessToken();
                LocalStorage.setUser();
                navigate('/');

            });
        } catch (ex) {
            LocalStorage.setRefreshToken()
            LocalStorage.setAccessToken();
            LocalStorage.setUser();
            navigate('/');
        }
    };

    useEffect(() => {
        const handleScroll = event => {
            if (window.scrollY >= 10) {
                setHeaderFixed(true);
            } else {
                setHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <header
                id="masthead"
                className={`sticky-top site-header headerMain 
      ${headerFixed ? "darkHeader" : ""} 
      ${navOpen ? "headerMainmob" : ""}
      `}
            >
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-xl p-0 d-block">
                        <div className="row">
                            <div className="col-xl-2 mob2col d-flex align-items-center headerTopBar">
                                <div className="d-flex align-items-center">
                                    <a
                                        href={`${SITE_URL}`}
                                        className="custom-logo-link"
                                        rel="home"
                                        aria-current="page"
                                    >
                                        <img
                                            width="162"
                                            height="36"
                                            src={darkLogo}
                                            className="custom-logo"
                                            alt="Qara"
                                            decoding="async"
                                        />
                                    </a>
                                    <div className="d-xl-none d-flex">
                                        <button
                                            id="nav-toggle"
                                            className={`nav-toggle menuToggler navbar-toggler-btn ps-4 ${
                                                navOpen ? "nav-open" : ""
                                            }`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarMain"
                                            aria-controls="navbarMain"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            onClick={() => setNavOpen(!navOpen)}
                                        >
                                            <span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 mob8col">
                                <div
                                    className={`collapse navbar-collapse con-end navbarMain row ${
                                        navOpen ? "show" : ""
                                    }`}
                                    id="navbarMain"
                                >
                                    <div className="mainMenudiv text-center">
                                        {(user?.customer && (
                                            <div className="menuTopNav d-xl-none ctaMOB loginLogout">
                                                <div className="menuTopNav">
                                                    <ul className="mainLoginmenu">
                                                        <figure>
                                                            <img
                                                                width="34"
                                                                height="34"
                                                                src={usericon}
                                                                className="userPic"
                                                                alt="Qara"
                                                                decoding="async"
                                                            />
                                                            <span className="userName">
                                                                {user.customer.firstName}
                                                                {user.customer.lastName}
                                                            </span>
                                                        </figure>
                                                        <li>
                                                            <a href="./index.html">Billing</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )) || <Link to="/" onClick={function () {
                                            setNavOpen(false)
                                        }} className="btn btn-primary d-xl-none">Login</Link>}
                                        <MainMenu/>
                                        {(user?.customer && (
                                            <div className="menuTopNav d-xl-none ctaMOB loginLogout">
                                                <div className="menuTopNav">
                                                    <ul className="mainLoginmenu">
                                                        <li>
                                                            <Link
                                                                className="btn btn-primary"
                                                                as="button"
                                                                onClick={handleLogout}
                                                            >
                                                                Log out
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )) || <a className="btn btn-outline-primary d-xl-none"
                                                 href='https://qara.app/try-qara-for-free/'>Try
                                            for free</a>}
                                    </div>
                                    <div className="mobSocial d-xl-none footer-social-link">
                                        <div className="menu-footer-social-container">
                                            <SocialLinks/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 d-none d-xl-block ctaDesktop">
                                {(user?.customer && (
                                    <div id="ctaDsktopmenu" className="dropdown userLog loginLogout">
                                        <div className="menuTopNav">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <img
                                                        width="34"
                                                        height="34"
                                                        src={usericon}
                                                        className="userPic"
                                                        alt="Qara"
                                                        decoding="async"
                                                    />
                                                    <span className="userName">
                                                    {user.customer.firstName}{" "}
                                                    {user.customer.lastName}
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigate('/billing')} as="button">Billing</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => navigate('/10dlc-registration')} as="button">10DLC Registration</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={handleLogout}>
                                                        Log out
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                )) || (
                                    <div id="menudesktop" className="menuTopNav userLogAct">
                                        <ul className="navbar-nav d-flex ms-auto navbar-nav-right justify-content-end align-items-center">
                                            <li className="loginBtn menu-item">
                                                <Link to="/login" className="mr-xl-3">Login</Link>
                                            </li>
                                            <li className="freetrial_butn menu-item">
                                                <Link to="https://qara.app/try-qara-for-free/" className="btn btn-primary">Try
                                                    for free</Link>

                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            {showSubscriptionError && (
                <PostSubscriptionErrorModal
                    showSubscriptionError={showSubscriptionError}
                    handleShowSubscriptionError={handleShowSubscriptionError}
                />
            )}
        </>
    );
};

export default Header;
