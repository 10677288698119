import React from "react";
import LightLogo from "../../assets/images/LightLogo.svg";
import Document from "../../assets/images/Document.svg";
import Wallet from "../../assets/images/Wallet.svg";
import { globalConstants } from "../../services/globalVariables";

const SITE_URL = globalConstants.SITE_URL;

export default function Sidebar() {
    return (
        <div className="left-sidebar">
            <a
                href={`${SITE_URL}`}
                className="logo-link"
                rel="home"
                aria-current="page"
            >
                <img
                    width="131"
                    height="28"
                    src={LightLogo}
                    className="logo"
                    alt="Qara"
                    decoding="async"
                />
            </a>
            <ul className="left-sidebar-menu">
                <li className="left-sidebar-menu-item">
                    <a href="/callrecords" className="left-sidebar-menu-item-link active">
                        <img src={Document} alt="Document icon" />
                        <span>Call records</span>
                    </a>
                </li>
                <li className="left-sidebar-menu-item">
                    <a href="/payment" className="left-sidebar-menu-item-link">
                        <img src={Wallet} alt="Document icon" />
                        <span>Payment</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}
