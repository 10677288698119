import React, { useState, useEffect } from "react";
import { TableSection } from "./TableSection";
import { getCdr } from "../../services/billing.js";
import emptyCall from "../../assets/images/emptyCalls.png";
import { Pagination } from "@mui/material";

export const Table = (props) => {
    const [info, setInfo] = useState([{}]);
    const company = localStorage.getItem("company");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { startDate, endDate } = props;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCdr(
                    250,
                    page,
                    Math.floor(startDate.getTime() / 1000),
                    Math.floor(endDate.getTime() / 1000)
                );
                setInfo(response?.data?.cdrs);
                setTotalPages(response?.data.totalPages);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [page, startDate, endDate]);

    const paginate = (e, pageNumber) => {
        setPage(pageNumber);
    };

    return (
        <div className="info-container">
            {info.length > 0 ? (
                <div className="table-box">
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Dest. type</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {info.map((personDetails, index) => (
                                <TableSection
                                    key={index}
                                    personDetails={personDetails}
                                    index={index}
                                />
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={paginate}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            height: "58px",
                            borderTop: "1px solid #AFB1B6",
                        }}
                    />
                </div>
            ) : (
                <div className="no-calls">
                    <img src={emptyCall} alt="empty calls" />
                    No calls detected within the chosen timeframe. Try a
                    different time range. We'll search again
                </div>
            )}
        </div>
    );
};
