import React, { useState, useEffect } from "react";
import infoIcon from "../../assets/images/svg/info-icon.svg";

const CompanyRegistrationDLC = ({ setStatus }) => {
    const [isCompanySectionVisible, setCompanySectionVisible] = useState(false);
    const [formData, setFormData] = useState({
        legalCompanyName: "",
        companyType: "",
        ein: "",
        address: "",
        state: "",
        stockSymbol: "",
        industry: "",
        dba: "",
        country: "United States",
        city: "",
        zipCode: "",
        stockExchange: "",
        website: "",
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formStatus, setFormStatus] = useState("Incomplete");

    const statusStyles = {
        Incomplete: { color: "red" },
        Pending: { color: "orange" },
        Approved: { color: "green" },
        Default: { color: "red" }
    };

    const getStatusStyle = (status) => {
        return statusStyles[status] || statusStyles.Default;
    };

    useEffect(() => {
        if (formStatus === "Pending") {
            const timer = setTimeout(() => {
                setFormStatus("Approved");
                setStatus("Approved");
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [formStatus, setStatus]);

    useEffect(() => {
        const savedFormData = localStorage.getItem("companyFormData");
        const submissionStatus = localStorage.getItem("formSubmitted");

        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
        }
        if (submissionStatus === "true") {
            setIsSubmitted(true);
            setFormStatus("Approved");
            setStatus("Approved");
        }
    }, [setStatus]);

    useEffect(() => {
        const {
            legalCompanyName,
            companyType,
            ein,
            address,
            state,
            industry,
            city,
            zipCode,
        } = formData;

        const allFieldsFilled =
            legalCompanyName.trim() &&
            companyType.trim() &&
            ein.trim() &&
            address.trim() &&
            state.trim() &&
            industry.trim() &&
            city.trim() &&
            zipCode.trim();

        setIsFormValid(allFieldsFilled);
        setCompanySectionVisible(!allFieldsFilled);
    }, [formData]);

    const toggleCompanySection = () => {
        setCompanySectionVisible(!isCompanySectionVisible);
    };

    const handleInputChange = (e) => {
        if (isSubmitted) return; // Prevent changes if submitted
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            localStorage.setItem("companyFormData", JSON.stringify(formData));
            localStorage.setItem("formSubmitted", "true");

            setIsSubmitted(true);
            setFormStatus("Pending");
            setStatus("Pending");
            alert("Form submitted and saved successfully!");
        } else {
            alert("Please fill all required fields.");
        }
    };

    return (
        <div className="container p-4 pb-0 bg-white rounded-4">
            <div className="border-bottom pb-4">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <h2 className="fs-4 fw-normal font-weight-bold pb-0 mb-0">
                        Company <span style={getStatusStyle(formStatus)} className="fw-normal fs-6 d-inline-block">{formStatus}</span>
                    </h2>
                    <button
                        onClick={toggleCompanySection}
                        style={{
                            backgroundColor: isCompanySectionVisible ? "#0A0A0A14" : "#fff",
                            width: "64px",
                            height: "64px",
                            borderRadius: "12px",
                            padding: "4px",
                            border: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {isCompanySectionVisible ? (
                            <div style={{ width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}></div>
                        ) : (
                            <div style={{ position: "relative", width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}>
                                <div style={{ position: "absolute", width: "21.33px", height: "2.67px", backgroundColor: "#666666", transform: "rotate(90deg)" }}></div>
                            </div>
                        )}
                    </button>
                </div>
                {formStatus === "Pending" && (
                    <div className="alert d-flex alert-warning border border-warning-subtle border-2 px-2 align-items-start" style={{ width: "555px" }} role="alert">
                        <img width="20" height="20" src={infoIcon} className="mx-2 my-1" alt="info Icon" decoding="async" />
                        <div>
                            <span>We will let you know once you are approved. This usually takes 5 minutes.</span>
                        </div>
                    </div>
                )}
            </div>

            {isCompanySectionVisible && (
                <form className="row mt-4" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Legal company name*</label>
                            <input
                                type="text"
                                name="legalCompanyName"
                                value={formData.legalCompanyName}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Company Type*
                            </label>
                            <select
                                name="companyType"
                                value={formData.companyType}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                            >
                                <option value="LLC">LLC</option>
                                <option value="Corporation">Corporation</option>
                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                <option value="Unlimited Companies">Unlimited Companies</option>
                                <option value="Public Companies">Public Companies</option>
                                <option value="Associate Companies">Associate Companies</option>
                                <option value="Companies in terms of Access to Capital">Companies in terms of Access to Capital</option>
                                <option value="Government Companies">Government Companies</option>
                                <option value="Foreign Companies">Foreign Companies</option>
                                <option value="Charitable Companies">Charitable Companies</option>
                                <option value="Dormant Companies">Dormant Companies</option>
                                <option value="Nidhi Companies">Nidhi Companies</option>
                                <option value="Public Financial Institutions">Public Financial Institutions</option>

                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">EIN*</label>
                            <input
                                type="text"
                                name="ein"
                                value={formData.ein}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Address/Street*
                            </label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">State*</label>
                            <select
                                name="state"
                                value={formData.state}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                            >
                                <option value="Mississippi">Mississippi</option>
                                <option value="CA">California</option>
                                <option value="NY">New York</option>
                                <option value="TX">Texas</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Stock Symbol
                            </label>
                            <input
                                type="text"
                                name="stockSymbol"
                                value={formData.stockSymbol}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Industry*
                            </label>
                            <select
                                name="industry"
                                value={formData.industry}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                            >
                                <option value="">Select industry</option>
                                <option value="Technology">Technology</option>
                                <option value="Finance">Finance</option>
                                <option value="Healthcare">Healthcare</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                DBA or brand name, if different from legal name
                            </label>
                            <input
                                type="text"
                                name="dba"
                                value={formData.dba}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Country of registration
                            </label>
                            <select
                                name="country"
                                value="United States"
                                className={`form-select p-3 bg-light`}
                                required
                                readOnly
                            >
                                <option value="United States">United States</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">City*</label>
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Zip code*
                            </label>
                            <input
                                type="text"
                                name="zipCode"
                                value={formData.zipCode}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Stock Exchange
                            </label>
                            <input
                                type="text"
                                name="stockExchange"
                                value={formData.stockExchange}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">
                                Website/Online Presence (optional)
                            </label>
                            <input
                                type="text"
                                name="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                    </div>

                    <div className="col-12 d-flex justify-content-end mb-3">
                        <button
                            type="submit"
                            className={`btn ${isSubmitted ? "btn-secondary" : "btn-primary"}`}
                            disabled={isSubmitted || !isFormValid}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CompanyRegistrationDLC;

