import { LocalStorage } from "../app/helpers";
import http from "./httpService";

export function postRefreshToken(token) {
    return http.post(`public/v1/user/otp/refresh/?refreshToken=${token}`);
}
// export function getCountries() {
//   return http.get(`public/v1/countries`);
// }
export function getSubscription(companyId) {
    return http.get(`private/v1/payments/subscription/${companyId}`);
}
export function postSubscription(companyId) {
    return http.post(`private/v1/payments/subscription/${companyId}`);
}
export function getTrialDaysLeft(companyId) {
    return http.get(`private/v1/companies/${companyId}`);
}
export function postPayments(companyId) {
    return http.post(`private/v1/payments/card/${companyId}`);
}
export function getPaymentsCard(companyId) {
    return http.get(`private/v1/payments/card/${companyId}`);
}
export function postDefaultCard(companyId, id, bodyData) {
    return http.post(`private/v1/payments/card/${companyId}/${id}`, bodyData);
}
export function deleteCard(companyId, id) {
    return http.delete(`private/v1/payments/card/${companyId}/${id}`);
}
export function getInvoice(companyId) {
    return http.get(`private/v1/payments/invoice/${companyId}`);
}
export function logout() {
    return http.post(`private/v1/user/otp/logout?loginType=PORTAL`);
}
export function getCdr(companyId, page, start, end) {
    return http.get(`private/v1/cdr/${companyId}?page=${page}&start=${start}&end=${end}`);
}
export function getCdrGroup(companyId, id) {
    return http.get(`private/v1/cdr/${companyId}/group/${id}`);
}
export const checkUserSubsription = async () => {
    const company = LocalStorage.getCompany();
    try {
        const subscriptionResponse = await getSubscription(company);
        return  subscriptionResponse.status;
        if (subscriptionResponse.status === 401) {
            // Handle successful subscription response
            console.log("Subscription API success");
            //handleSubscriptionStatus(subscriptionResponse.data);
            console.log(
                subscriptionResponse.data,
                "subscriptionResponse.data 11111"
            );
        } else {
            console.log(
                "Subscription API failed with status:",
                subscriptionResponse.status
            );
        }
    } catch (error) {
        if (error?.response?.status) return error?.response?.status;
        // Handle error
        console.error("Axios error in handleSubscriptionCheck:", error);
        throw error; // Add this line to rethrow the error if needed
    }
};
